interface Props {
  shown: boolean;
  proximo(event: React.FormEvent<HTMLFormElement>): void;
}

const Porte = (props: Props) => {
  return (
    <section className={props.shown ? "shown" : "hidden"}>
      <form
        onSubmit={props.proximo}
        id="caracteristicas"
        className="formulario"
      >
        <h2>Características</h2>
        <>
          <h3>Porte da Obra</h3>
          <div className="linha-formulario">
            <div className="input">
              <label htmlFor="pequeno">Pequeno</label>
              <input type="checkbox" name="pequeno" id="pequeno" />
            </div>
            <div className="input">
              <label htmlFor="medio">Médio</label>
              <input type="checkbox" name="medio" id="medio" />
            </div>
            <div className="input">
              <label htmlFor="grande">Grande</label>
              <input type="checkbox" name="grande" id="grande" />
            </div>
          </div>
        </>
        <>
          <h3>Padrão da Obra</h3>
          <div className="linha-formulario">
            <div className="input">
              <label htmlFor="popular">Popular</label>
              <input type="checkbox" name="popular" id="popular" />
            </div>
            <div className="input">
              <label htmlFor="padrao">Médio</label>
              <input type="checkbox" name="padrao" id="padrao" />
            </div>
            <div className="input">
              <label htmlFor="alto">Alto</label>
              <input type="checkbox" name="alto" id="alto" />
            </div>
          </div>
        </>
        <div className="linha-formulario">
          <div></div>
          <button type="submit">Próximo</button>
        </div>
      </form>
    </section>
  );
};

export default Porte;
