import { useState } from "react";
import "./App.css";
import Apresentacao from "./Components/Apresentacao/Apresentacao";
import Complementos from "./Components/Formularios/Complementos";
import Logo from "./Components/Logo/Logo";
import Aplicacoes from "./Components/Formularios/Aplicacoes";
import Tags from "./Tags";
import Porte from "./Components/Formularios/Porte";
import Estrutura from "./Components/Formularios/Estrutura";

function App() {
  const [states, setStates] = useState<boolean[]>([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [complementos, setComplementos] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [slidesContent, setSlidesContent] = useState<number[]>([]);
  const [slidesIdx, setSlidesIdx] = useState<Set<number>>(new Set<number>());
  const tags = new Tags();

  return (
    <section className="App">
      <article className="App-header">
        <Logo reset={reset} shown={states[4]} />
        <Porte shown={states[0]} proximo={handlePorte} />
        <Estrutura
          shown={states[1]}
          anterior={anterior}
          proximo={handleEstrutura}
        />
        <Aplicacoes
          shown={states[2]}
          anterior={() => anterior()}
          proximo={handleAplicacoes}
        />
        <Complementos
          shown={states[3]}
          anterior={() => anterior()}
          apresentar={apresentar}
        />
        <Apresentacao
          shown={states[4]}
          slidesContent={slidesContent}
          modulight={complementos[0]}
          caixa={complementos[1]}
          parceiro={complementos[2]}
        />
      </article>
    </section>
  );

  function reset() {
    setSlidesContent([]);
    setSlidesIdx(new Set<number>());
    setStates([true, false, false, false]);
  }
  function proximo() {
    const idx = states.indexOf(true);
    const nextStates = states.slice();
    nextStates[idx] = false;
    nextStates[idx + 1] = true;

    setStates(nextStates);
  }
  function anterior() {
    const idx = states.indexOf(true);
    const nextStates = states.slice();
    nextStates[idx] = false;
    nextStates[idx - 1] = true;

    setStates(nextStates);
  }

  function addIndicesToSet(set: Set<number>, indices: number[]) {
    indices.forEach((idx) => {
      set.add(idx);
    });
  }
  function addIndicesToArray(array: Array<number>, indices: number[]) {
    indices.forEach((idx) => {
      array.push(idx);
    });
  }

  function handlePorte(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let nextSlidesIdx; 

    const porte: number[] = [];
    if (event.currentTarget.pequeno.checked)
      addIndicesToArray(porte, tags.getIndicesFromTag("pequeno"));
    if (event.currentTarget.medio.checked)
      addIndicesToArray(porte, tags.getIndicesFromTag("médio"));
    if (event.currentTarget.grande.checked)
      addIndicesToArray(porte, tags.getIndicesFromTag("grande"));

    const padrao: number[] = [];
    if (event.currentTarget.popular.checked)
      addIndicesToArray(padrao, tags.getIndicesFromTag("popular"));
    if (event.currentTarget.padrao.checked)
      addIndicesToArray(padrao, tags.getIndicesFromTag("padrão"));
    if (event.currentTarget.alto.checked)
      addIndicesToArray(padrao, tags.getIndicesFromTag("alto"));

    if (padrao.length > 0) {
      const indices = padrao.filter((idx) => porte.includes(idx));
      nextSlidesIdx = new Set<number>(indices);
    } else {
      nextSlidesIdx = new Set<number>(porte);
    }
    setSlidesIdx(nextSlidesIdx);

    proximo();
  }

  function handleEstrutura(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const estrutura: number[] = [];
    if (event.currentTarget.autoportante.checked)
      addIndicesToArray(estrutura, tags.getIndicesFromTag("autoportante"));
    if (event.currentTarget.metalica.checked)
      addIndicesToArray(estrutura, tags.getIndicesFromTag("metálica"));
    if (event.currentTarget.concreto.checked)
      addIndicesToArray(estrutura, tags.getIndicesFromTag("concreto"));

    const uso: number[] = [];
    if (event.currentTarget.residencial.checked)
      addIndicesToArray(uso, tags.getIndicesFromTag("residencial"));
    if (event.currentTarget.comercial.checked)
      addIndicesToArray(uso, tags.getIndicesFromTag("comercial"));
    if (event.currentTarget.publico.checked)
      addIndicesToArray(uso, tags.getIndicesFromTag("público"));

    const estIdx = estrutura.filter((idx) => slidesIdx.has(idx));
    const usoIdx = uso.filter((idx) => slidesIdx.has(idx));

    const nextSlidesIdx = new Set<number>();
    addIndicesToSet(nextSlidesIdx, estIdx);
    addIndicesToSet(nextSlidesIdx, usoIdx);
    if (nextSlidesIdx.size > 0) setSlidesIdx(nextSlidesIdx);

    proximo();
  }

  function handleAplicacoes(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let indices: number[] = [];

    const vedIdx: number[] = [];
    if (event.currentTarget.vedacao.checked) {
      indices = tags.getIndicesFromTag("vedação");
      addIndicesToArray(
        vedIdx,
        indices.filter((idx) => slidesIdx.has(idx))
      );
    }

    const lajeIdx: number[] = [];
    if (event.currentTarget.laje.checked) {
      indices = tags.getIndicesFromTag("laje");
      addIndicesToArray(
        lajeIdx,
        indices.filter((idx) => slidesIdx.has(idx))
      );
    }

    const repeticaoIdx: number[] = [];
    if (event.currentTarget.repeticao.checked) {
      indices = tags.getIndicesFromTag("repetição");
      addIndicesToArray(
        repeticaoIdx,
        indices.filter((idx) => slidesIdx.has(idx))
      );
    }

    const nextSlidesIdx = new Set<number>();
    addIndicesToSet(nextSlidesIdx, vedIdx);
    addIndicesToSet(nextSlidesIdx, lajeIdx);
    addIndicesToSet(nextSlidesIdx, repeticaoIdx);

    if (event.currentTarget.aparente.checked)
      addIndicesToSet(nextSlidesIdx, tags.getIndicesFromTag("aparente"));

    if (nextSlidesIdx.size > 0) setSlidesIdx(nextSlidesIdx);

    const nextSlidesContent = Array.from(slidesIdx);
    nextSlidesContent.sort();
    setSlidesContent(nextSlidesContent);

    proximo();
  }

  function apresentar(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const modulight = event.currentTarget.modulight;
    const caixa = event.currentTarget.caixa;
    const parceiro = event.currentTarget.parceiro;

    setComplementos([modulight.checked, caixa.checked, parceiro.checked]);
    proximo();
  }
}

export default App;
