class Tags {
  tags = [
    ["pequeno", "popular", "autoportante", "vedação", "laje", "residencial", "repetição"],
    ["pequeno", "popular", "autoportante", "vedação", "residencial", "repetição"],
    ["pequeno", "popular", "autoportante", "vedação", "residencial", "repetição"],
    ["pequeno", "popular", "autoportante", "vedação", "residencial", "repetição"],
    ["grande", "padrão", "autoportante", "vedação", "comercial", "repetição"],
    ["médio", "alto", "metálica", "vedação", "residencial", "aparente"],
    ["pequeno", "alto", "metálica", "vedação", "laje", "residencial", "repetição", "aparente"],
    ["pequeno", "alto", "metálica", "vedação", "laje", "residencial", "repetição"],
    ["pequeno", "alto", "metálica", "vedação", "residencial", "repetição"],
    ["grande", "alto", "metálica", "laje", "comercial", "aparente"],
    ["médio", "alto", "metálica", "vedação", "laje", "residencial"],
    ["médio", "alto", "metálica", "vedação", "comercial", "aparente"],
    ["médio", "alto", "metálica", "vedação", "comercial", "aparente"],
    ["grande", "alto", "concreto", "vedação", "residencial"],
    ["grande", "alto", "concreto", "vedação", "residencial", "aparente"],
    ["grande", "padrão", "concreto", "vedação", "comercial", "aparente"],
    ["grande", "alto", "concreto", "vedação", "comercial"],
    ["grande", "padrão", "concreto", "vedação", "comercial"],
    ["grande", "alto", "concreto", "vedação", "comercial"],
    ["grande", "alto", "concreto", "vedação", "comercial"],
    ["grande", "alto", "concreto", "vedação", "comercial"],
    ["médio", "alto", "concreto", "vedação", "laje", "comercial", "repetição"],
    ["grande", "padrão", "concreto", "vedação", "residencial"],
    ["médio", "alto", "concreto", "laje", "comercial"],
    ["médio", "alto", "concreto", "laje", "comercial"],
    ["pequeno", "popular", "autoportante", "vedação", "residencial", "repetição"],
    ["grande", "alto", "metálica", "vedação", "laje", "público", "aparente"],
    ["grande", "padrão", "concreto", "laje", "público", "repetição"],
    ["grande", "padrão", "concreto", "laje", "público", "repetição"],
  ];

  getIndicesFromTag(pattern: string) {
    const indices = this.tags
      .map((image: string[], idx: number) => ({ image, idx }))
      .filter(({ image }) => image.some((tag: string) => tag === pattern))
      .map(({ idx }) => idx + 9);

    return indices;
  }
  getIndicesFromMultipleTags(pattern: string[]) {
    const indices = this.tags
      .map((image: string[], idx: number) => ({ image, idx }))
      .filter(({ image }) => pattern.every((tag) => image.includes(tag)))
      .map(({ idx }) => idx + 9);

    return indices;
  }
}

export default Tags;
