import "./apresentacao.css";
import { useState } from "react";

interface Props {
  shown: boolean;
  slidesContent: number[];
  modulight: boolean;
  caixa: boolean;
  parceiro: boolean;
}

const Apresentacao = (props: Props) => {
  const slidesIniciais = Array.from(Array(8), (e, i) => ++i);
  const slidesModulight = Array.from(Array(7), (e, i) => i + 39);
  const slidesCaracteristicas = Array.from(Array(7), (e, i) => i + 46);
  const slidesCaixa = Array.from(Array(3), (e, i) => i + 53);
  const slidesVantagens = Array.from(Array(5), (e, i) => i + 56);
  const slidesParceiro = Array.from(Array(4), (e, i) => i + 61);
  const slideFinal = [65];

  const complementos = [props.slidesContent];
  if (props.modulight) complementos.push(slidesModulight);
  complementos.push(slidesCaracteristicas);
  if (props.caixa) complementos.push(slidesCaixa);
  complementos.push(slidesVantagens);
  if (props.parceiro) complementos.push(slidesParceiro);
  complementos.push(slideFinal);

  complementos.forEach((slides) => {
    slides.forEach((slide) => {
      slidesIniciais.push(slide);
    });
  });

  const [current, setCurrent] = useState<number>(0);

  return (
    <section id="apresentacao" className={props.shown ? "shown" : "hidden"}>
      <span
        id="left"
        className="control left"
        onClick={() => handleLeftClick()}
      >
        &lt;
      </span>
      <img
        src={"./Slides/" + slidesIniciais[current] + ".jpg"}
        alt="Slide de Apresentação"
        id="slide"
      />
      <span
        id="right"
        className="control right"
        onClick={() => handleRightClick()}
      >
        &gt;
      </span>
    </section>
  );

  function handleLeftClick() {
    if (current === 0) {
      setCurrent(slidesIniciais.length - 1);
    } else {
      setCurrent(current - 1);
    }
  }
  function handleRightClick() {
    if (current === slidesIniciais.length - 1) {
      setCurrent(0);
    } else {
      setCurrent(current + 1);
    }
  }
};

export default Apresentacao;
