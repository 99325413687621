interface Props {
  shown: boolean;
  anterior(): void;
  proximo(event: React.FormEvent<HTMLFormElement>): void;
}

const Estrutura = (props: Props) => {
  return (
    <section className={props.shown ? "shown" : "hidden"}>
      <form
        onSubmit={props.proximo}
        id="caracteristicas"
        className="formulario"
      >
        <h2>Características</h2>
        <>
          <h3>Tipo de Estrutura</h3>
          <div className="linha-formulario">
            <div className="input">
              <label htmlFor="autoportante">Autoportante</label>
              <input type="checkbox" name="autoportante" id="autoportante" />
            </div>
            <div className="input">
              <label htmlFor="metalica">Metálica</label>
              <input type="checkbox" name="metalica" id="metalica" />
            </div>
            <div className="input">
              <label htmlFor="concreto">Concreto</label>
              <input type="checkbox" name="concreto" id="concreto" />
            </div>
          </div>
        </>
        <>
          <h3>Uso da Obra</h3>
          <div className="linha-formulario">
            <div className="input">
              <label htmlFor="residencial">Residencial</label>
              <input type="checkbox" name="residencial" id="residencial" />
            </div>
            <div className="input">
              <label htmlFor="comercial">Comercial</label>
              <input type="checkbox" name="comercial" id="comercial" />
            </div>
            <div className="input">
              <label htmlFor="publico">Público</label>
              <input type="checkbox" name="publico" id="publico" />
            </div>
          </div>
        </>
        <div className="linha-formulario">
          <button type="button" onClick={props.anterior}>
            Anterior
          </button>
          <button type="submit">Próximo</button>
        </div>
      </form>
    </section>
  );
};

export default Estrutura;
