import "./logo.css";
import logo from "../../Images/logo.png";

interface Props {
  reset(): void;
  shown: boolean;
}

const Logo = (props: Props) => {
  return (
    <div className="logo" onClick={props.reset}>
      <span id="back" className={props.shown ? "shown" : "hidden"}>
        &lt;
      </span>
      <img
        className={props.shown ? "hidden" : "shown"}
        src={logo}
        alt="Logo da Lightwall"
        id="logo"
      />
    </div>
  );
};

export default Logo;
