interface Props {
  shown: boolean;
  anterior(): void;
  proximo(event: React.FormEvent<HTMLFormElement>): void;
}

const Aplicacoes = (props: Props) => {
  return (
    <section className={props.shown ? "shown" : "hidden"}>
      <form onSubmit={props.proximo} id="aplicacoes" className="formulario">
        <h2 className="title">Aplicações</h2>
        <div className="linha-formulario input">
          <label htmlFor="vedacao">Vedação</label>
          <input type="checkbox" name="vedacao" id="vedacao" />
        </div>
        <div className="linha-formulario input">
          <label htmlFor="laje">Laje</label>
          <input type="checkbox" name="laje" id="laje" />
        </div>
        <div className="linha-formulario input">
          <label htmlFor="aparente">Aparente</label>
          <input type="checkbox" name="aparente" id="aparente" />
        </div>
        <div className="linha-formulario input">
          <label htmlFor="repeticao">Repetição</label>
          <input type="checkbox" name="repeticao" id="repeticao" />
        </div>
        <div className="linha-formulario">
          <button type="button" onClick={props.anterior}>
            Anterior
          </button>
          <button type="submit">Próximo</button>
        </div>
      </form>
    </section>
  );
};

export default Aplicacoes;
